








import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import  Config  from '@/api/models/config';
import MultipleSessionShowTemplate from '@/components/MultipleSessionShowTemplate.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';;

@Component({
  components: {
    MultipleSessionShowTemplate
  }
})
export default class MultipleSessionShow extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  dialog: boolean = true;

  config!: Config;

  active: boolean = false;

  async created() {
    await this.globalLoadingScreenModule.track(
      this.getConfig(),
    );
  }

  async getConfig() {
    this.config = await this.api.getConfig();
  }

  async onLeave() {
    if (this.active) return;
    try {
      this.active = true;
      const loginUri = await this.api.postLogout();
      window.location.href = loginUri;
    } catch {
      window.location.href = '/500.html';
    } finally {
      this.active = false;
    }
  }
}
