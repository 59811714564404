







import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import  Config  from '@/api/models/config';
import MultipleSessionShowConfirm from '@/components/MultipleSessionShowConfirm.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';;

@Component({
  components: {
    MultipleSessionShowConfirm
  }
})
export default class MultipleSessionShowTemplate extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Prop({ required: true})
  dialog!: boolean;

  @Prop({ required: true})
  config!: Config;

  @Prop({ required: true})
  leave!: () => void;
}
